import { useContext, useState } from 'react';
import { CommonContext } from '../App';

import ScrollMotion from './ScrollMotion';
import VideoPlayer from './VideoPlayer';
import Lottie from 'react-lottie-player';
import TeaserPlayButton from '../assets/teaser-play-button.json';

import './HomeTeaser.scss';

const playButtonSegments: { [state: string]: [number, number] } = {
  init: [0, 250],
  mouseOver: [250, 265],
  mouseOut: [265, 250],
  mouseDown: [265, 250],
  mouseUp: [250, 265],
};

type HomeTeaserProps = {
  image: string;
  title: string;
  subtitle: string;
  videoLink?: string;
};

const HomeTeaser = ({ image, title, subtitle }: HomeTeaserProps) => {
  const { state } = useContext(CommonContext);

  // video player

  const [videoPlayerVisible, setVideoPlayerVisible] = useState(false);

  const onClick = (evt?: any) => {
    if (evt) {
      evt.nativeEvent?.stopImmediatePropagation();
      evt.preventDefault();
      evt.stopPropagation();
    }
    setVideoPlayerVisible(true);
    return null;
  };

  // lottie
  const [segments, setSegments] = useState<[number, number]>(playButtonSegments.init);

  const mouseOver = () => {
    setSegments(playButtonSegments.mouseOver);
  };
  const mouseOut = () => {
    setSegments(playButtonSegments.mouseOut);
  };
  const mouseDown = () => {
    setSegments(playButtonSegments.mouseDown);
  };
  const onMouseUp = () => {
    setSegments(playButtonSegments.mouseUp);
  };

  return (
    <div className="home-teaser">
      <ScrollMotion
        className="home-teaser__animation"
        active={!state.initialAnimation}
        threshold={0.1}
        triggerOnce={true}
        hiddenState={{
          scaleX: 1.4,
          zIndex: 3,
        }}
        visibleState={{
          transition: {
            duration: 0.5,
            easing: 'ease-out',
          },
          scaleX: 1,
          zIndex: 1,
        }}
      >
        <img className="home-teaser__image" src={image} alt="It's time to frame the future" />
      </ScrollMotion>

      <div className="home-teaser__controls">
        <div className="home-teaser__heading">
          <h1 className="home-teaser__title">{title}</h1>
          <p className="home-teaser__subtitle">{subtitle}</p>
        </div>
        <div
          className="home-teaser__play"
          onMouseEnter={mouseOver}
          onMouseLeave={mouseOut}
          onMouseDown={mouseDown}
          onMouseUp={onMouseUp}
          onClick={onClick}
        >
          <Lottie
            loop={false}
            animationData={TeaserPlayButton}
            play={true}
            style={{ marginTop: '3px' }}
            segments={segments}
            direction={1}
          />
        </div>
      </div>

      <VideoPlayer vimeoId={606429260} visible={videoPlayerVisible} hideOverlay={() => setVideoPlayerVisible(false)} />
    </div>
  );
};

export default HomeTeaser;
